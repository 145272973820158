import Cookies from "js-cookie";

export type ArticleVisitedProps = {
  articleId: string;
};

export type SphUserArticleCountCookie = {
  time: number;
  count: number;
  nids: string[];
  expire_date: Date;
};

export default function setArticleVisitCookie({
  articleId,
}: ArticleVisitedProps) {
  const currentTime = Date.now();
  const cookieName = "sph_user_article_count";

  // Retrieve the existing cookie
  const existingCookie = Cookies.get(cookieName);

  const cookieExpiry = new Date();
  cookieExpiry.setHours(23, 59, 59, 999); // Set to 23:59:59.999 of today

  let cookieData = {
    time: currentTime,
    count: 1, // Default count
    nids: [] as string[], // Specify nids as an array of strings
    expire_date: cookieExpiry,
  };

  // If the cookie exists, parse it
  if (existingCookie) {
    cookieData = JSON.parse(existingCookie) as SphUserArticleCountCookie;
    // Check if the new ID is already in the nids array
    if (!cookieData.nids.includes(articleId)) {
      cookieData.count = cookieData.nids.length + 1;
      cookieData.time = currentTime;
      cookieData.nids.push(articleId); // Added new ID if not present
    }
  } else {
    // If this is a new cookie, add the new ID
    cookieData.nids.push(articleId);
  }

  // Set the cookie with the updated data
  Cookies.set(cookieName, JSON.stringify(cookieData), {
    expires: cookieExpiry,
    path: "/",
  });
}
