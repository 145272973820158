import Audio from "@components/Audio/Audio";
import PodcastCard from "@components/Card/PodcastCard";
import SideBlock from "@components/Container/SideBlock";
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import WrapperBlock from "@container/WrapperBlock";
import Heading from "@elements/Typography/Heading";
import fetchTMApi from "@helper/fetchTMApi";
import useSwrApi from "@hooks/useSwrApi";
import { isClientSide } from "@src/app/components/helper/utils";
import { type PodcastProcessedResponse } from "@transformer/usePodcastResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import PodcastMetaData from "./PodcastMetaData";

export default function Podcast() {
  const { data } = useSwrApi<PodcastProcessedResponse[]>("podcasts");
  const { level1: id } = useParams();
  const [podcasts, setPodcasts] = useState<PodcastProcessedResponse[]>();
  const [playing, setPlaying] = useState<
    PodcastProcessedResponse | undefined
  >();

  useEffect(() => {
    if (!isClientSide) return;
    const fetchData = async () => {
      if (!data || data.length === 0) return;
      let nowPlaying = false;
      try {
        const podcast: PodcastProcessedResponse[] = id
          ? await fetchTMApi<PodcastProcessedResponse[]>(
              `podcasts/${id}`,
              "GET",
            )
          : data;

        setPlaying(podcast[0]);
        nowPlaying = id ? false : true;
      } catch (error) {
        nowPlaying = true;
        setPlaying(data[0]);
      }

      const updatedPodcasts = data.map((podcast, index) =>
        index === 0
          ? { ...podcast, nowPlaying: nowPlaying ? true : false }
          : podcast,
      );
      setPodcasts(updatedPodcasts);
    };

    void fetchData();
  }, [id, data]);

  const handleNowPlaying = useMemo(
    () => (id: string) => {
      setPodcasts(
        (prevValue) =>
          prevValue &&
          prevValue.map((video) => ({
            ...video,
            nowPlaying: video.id === id,
          })),
      );
    },
    [setPodcasts],
  );

  const handleOnClickThumbnail = useCallback(
    (props: PodcastProcessedResponse) => {
      // eslint-disable-next-line react/prop-types
      const { id } = props;
      handleNowPlaying(id);
      setPlaying(props);
    },
    [handleNowPlaying, setPlaying],
  );

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const sectionName = "podcasts";
  const { gaData, metaTags } = PodcastMetaData(sectionName);

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock
          showTitle={false}
          sectionName={sectionName}
          showBreadcrumbs={true}
        >
          {playing && <Audio {...playing} />}
          <Heading className="text-3xl font-bold font-primary">
            All Episodes
          </Heading>
          <div>
            {podcasts &&
              Array.isArray(podcasts) &&
              podcasts.map((podcast) => (
                <button
                  key={podcast.id}
                  onClick={() => {
                    handleOnClickThumbnail(podcast);
                  }}
                >
                  <PodcastCard
                    fullDate={podcast.dateDuration}
                    imageField={{ url: podcast.thumbnail }}
                    title={podcast.name}
                    paragraph={podcast.description}
                    linkTo=""
                    displayWidth={0}
                    displayHeight={0}
                    elements={{ hasDate: true }}
                  />
                </button>
              ))}
          </div>
        </MainBlock>
        <SideBlock
          className="hidden lg:block"
          pageAdTargetValue={pageAdTargetValue}
        />
      </WrapperBlock>
    </MainContainer>
  );
}
