export const mySPHOpenLogin = () => {
  if (!window._mySPHObj) return;
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  window._mySPHObj.openLogin();
};

export const mySPHOpenLogout = () => {
  if (!window._mySPHObj) return;
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  window._mySPHObj.openLogout();
};

export const mySPHOpenSignUp = () => {
  if (!window._mySPHObj) return;
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  window._mySPHObj.openSignUp();
};

export const mySPHOpenUpdatePwd = () => {
  if (!window._mySPHObj) return;
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  window._mySPHObj.openUpdatePassword(); // This method doesn't work
};

export const mySPHOpenResendVerificationMail = () => {
  if (!window._mySPHObj) return;
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  window._mySPHObj.openResendVerificationMail();
};

import Cookies from "js-cookie";

export const getUserType = () => Cookies.get("mySPHUserType");
