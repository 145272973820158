import SideBlock from "@components/Container/SideBlock";
import WrapperBlock from "@components/Container/WrapperBlock";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import RenderArticleBlocks from "@elements/RenderArticleBlocks/RenderArticleBlocks";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import OutbrainMainContainer, {
  AdsSettings,
} from "@src/app/components/Container/OutbrainMainContainer";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import {
  ArticleAdPageTargetingType,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import { useState } from "react";

import { PayloadResponseType } from "./Article.server";
import ArticleContentCard from "./ArticleContentCard";
import ArticleInfiniteScroll from "./ArticleInfiniteScroll";
import ArticleMetaData from "./ArticleMetaData";

export default function ArticlePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const [currentArticle, setCurrentArticle] = useState<ProcessedArticleData>();
  const adsSettings: AdsSettings = useGlobalAdSetting();
  const { isScreenLG } = useWindowSize();

  if (
    dataLoaderResponse.type === ResponseType.CLIENT_ERROR ||
    dataLoaderResponse.type === ResponseType.SERVER_ERROR
  ) {
    return dataLoaderResponse.type === ResponseType.CLIENT_ERROR ? (
      <NotFound />
    ) : (
      <ErrorPage message={dataLoaderResponse.payload ?? ""} />
    );
  }

  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;

  const mainArticle = dataLoaderResponse.payload.article;
  const sectionName = mainArticle.sectionName;
  const relatedStories = dataLoaderResponse.payload.relatedStories;

  const articlePageTarget: ArticleAdPageTargetingType[] = [
    { key: "page_number", value: "1" },
    { key: "tmarticleid", value: mainArticle.id },
    {
      key: "tmtags",
      value: mainArticle.tags?.map((tags) => tags.name).join(", ") || "",
    },
  ];

  const handleNewArticle = (newArticles: ProcessedArticleData) => {
    if (newArticles.id !== currentArticle?.id) {
      setCurrentArticle(newArticles);
    }
  };

  const { gaData, metaTags } = ArticleMetaData({
    article: currentArticle || mainArticle,
    isMainArticle: currentArticle ? false : true,
  });
  return (
    <OutbrainMainContainer
      metaTagsProps={metaTags}
      gaData={gaData}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
      suppliedAdsSettings={adsSettings}
    >
      <div id={mainArticle.id} className="article-card">
        <WrapperBlock className="hover:text flex flex-col lg:flex-row justify-between">
          <ArticleContentCard
            articleData={mainArticle}
            relatedStories={relatedStories}
            pageAdTargetValue={pageAdTargetValue}
            pageNumber={0}
            isInfiniteScrollArticle={false}
            hasOutbrain={adsSettings.isOutbrainEnabled}
          />
          {isScreenLG ? (
            <SideBlock
              sectionName={sectionName}
              pageAdTargetValue={pageAdTargetValue}
              promoteEpaper={true}
              articlePageTarget={articlePageTarget}
            />
          ) : (
            <>
              {/* Render first block on default article */}
              <RenderArticleBlocks
                pageNumber={0}
                pageUrl={mainArticle.urlPath ? mainArticle.urlPath : ""}
                hasOutbrain={adsSettings.isOutbrainEnabled}
              />
              <AdsImu2
                sectionName={sectionName}
                pageAdTargetType={pageAdTargetValue}
                rootClassName="py-md"
                articlePageTarget={articlePageTarget}
              />
            </>
          )}
        </WrapperBlock>
      </div>

      <ArticleInfiniteScroll
        mainArticle={mainArticle}
        pageAdTargetValue={pageAdTargetValue}
        onNewArticle={handleNewArticle}
        hasOutbrain={adsSettings.isOutbrainEnabled}
      />
    </OutbrainMainContainer>
  );
}
