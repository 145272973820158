import type { ReactElement } from "react";

type NoResultProps = {
  searchedText: string;
};

export default function NoResult({
  searchedText,
}: NoResultProps): ReactElement {
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col gap-8">
          <div className="bg-red-400 my-2">
            <div className="p-2 flex items-center flex-col">
              <div className="font-regular italic text-black-200 text-2sm lg:text-[30px] mb-2">
                {searchedText}
              </div>
            </div>
          </div>
        </div>
        <p className="text-2sm">
          மன்னிக்கவும், உங்கள் தேடலுக்கு பொருத்தமான தகவல்கள் எதுவும்
          கிடைக்கவில்லை.
        </p>
      </div>
    </>
  );
}
