import Audio from "@components/Audio/Audio";
import Heading from "@elements/Typography/Heading";
import useSwrApi from "@hooks/useSwrApi";
import { type PodcastProcessedResponse } from "@transformer/usePodcastResponse";
import { useEffect, useState } from "react";

export default function PodcastPlayer() {
  const { data } = useSwrApi<PodcastProcessedResponse[]>("podcasts");
  const [playing, setPlaying] = useState<
    PodcastProcessedResponse | undefined
  >();

  useEffect(() => {
    const fetchData = () => {
      if (!data || data.length === 0) return;
      const podcast: PodcastProcessedResponse[] = data;
      setPlaying(podcast[0]);
    };

    fetchData();
  }, [data]);

  return (
    <>
      {playing && (
        <div className="border-t border-pink-300 pt-5">
          <Heading Element="h6" className="font-primary font-bold text-md mb-4">
            <a href="/podcasts" className="text-red-100">
              <span className="hover:underline">வலையொளி</span>
              <span className="ml-5 font-bold">{">"}</span>
            </a>
          </Heading>
          <Audio {...playing} />
        </div>
      )}
    </>
  );
}
