import { useEffect } from "react";

type Props = {
  id: string;
  value: string;
  portion?: "head" | "body";
  noscript?: boolean;
};

export default function useScriptFunction({
  id,
  value,
  portion = "head",
  noscript = false,
}: Props) {
  useEffect(() => {
    if (document.getElementById(id)) {
      return;
    }

    let script;

    if (value) {
      if (!noscript) {
        script = document.createElement("script");
        script.type = "text/javascript";
      } else {
        script = document.createElement("noscript");
      }

      script.innerHTML = value;
      script.id = id;
      document[portion].appendChild(script);
    }
  }, [id, value, portion, noscript]);
}
