import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function PodcastMetaData(section: string): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: section,
    title: `${section}_index`,
    contentcat: 1,
    contenttype: undefined,
  };

  const metaTags: MetaTagsProps = {
    title:
      "வலையொளி - Stream the Latest Podcasts: News, Entertainment, Education & More | Tamil Murasu",
    description:
      "வலையொளி | Listen to the latest Tamil Podcasts of Singapore, Tamilnadu, India, World & Sports. சிறந்த தமிழ் வலையொளிகளை கேட்டு மகிழுங்கள்: செய்திகள், இசை, கதைகள் & மேலும்",
    slug: section,
    keywords: [
      "தமிழ் வலையொளி",
      "இன்றைய வலையொளி",
      "Latest Podcasts",
      "Latest Tamil Podcasts",
      "Latest News Podcasts",
      "Podcasts",
    ],
  };

  return {
    gaData,
    metaTags,
  };
}
