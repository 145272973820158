import { Image } from "@elements/ResponsiveImage/Image";

export type VideoPosterProps = {
  src: string;
};

export default function PodcastPoster({ src }: VideoPosterProps) {
  return (
    <div className="w-[64px] h-[64px] lg:w-[160px] lg:h-[160px]">
      <Image
        objectFit="contain"
        src={src}
        className="w-[64px] h-[64px] lg:w-[160px] lg:h-[160px]"
        srcWidth={160}
        srcHeight={160}
        alt="brightcove video"
        dataTestId="image"
      />
    </div>
  );
}
