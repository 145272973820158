import { JobListingMap } from "@src/api/endpoint-handlers/getJobsList";
import Heading from "@src/app/components/Elements/Typography/Heading";
import useSwrApi from "@src/app/hooks/useSwrApi";
import { twMerge } from "tailwind-merge";

const JobsCard = ({
  imgUrl,
  title,
  url,
  count,
}: {
  imgUrl: string;
  title: string;
  url: string;
  count: string;
}) => {
  return (
    <a
      className="flex gap-2.5 w-full lg:w-[calc(50%-5px)] bg-[white]"
      href={url}
    >
      <img alt={title} height={88} width={123} src={imgUrl} />
      <div className="flex-col">
        <p
          className={twMerge(
            "font-bold font-primary text-red-100 text-xs leading-300",
          )}
        >
          {title}
        </p>
        <p
          className={twMerge(
            "font-medium font-primary text-base lg:text-xs leading-300",
          )}
        >
          {count} jobs
        </p>
      </div>
    </a>
  );
};

export const JobsListing = () => {
  const { data: jobsListingMap } = useSwrApi<JobListingMap>(`jobs-listing`);
  const jobsList = jobsListingMap
    ? Object.keys(jobsListingMap).map(
        (mapKey: string) => jobsListingMap[mapKey],
      )
    : [];
  return (
    <div className="lg:mt-md pb-5 border-b border-pink-300">
      <Heading
        Element="h2"
        className="text-gray-100 text-2.5md font-bold mb-md"
      >
        சிங்கப்பூர் வேலைகள்
      </Heading>
      <div className="flex flex-col justify-center bg-gray-500">
        <div className="flex justify-between flex-wrap p-xs gap-2.5">
          {jobsList.map((job, index) => (
            <JobsCard
              count={job.totaljob}
              url={job.url}
              key={index}
              imgUrl={job.img_url}
              title={job.tamil_title}
            />
          ))}
        </div>
        <a
          className={twMerge(
            "m-auto font-bold font-primary text-base text-red-100 lg:text-2sm leading-300 pb-100 hover:underline hover:cursor-pointer hover:text-red-200",
          )}
          href="/jobs"
        >
          எல்லா வேலைகளையும் காண்க
        </a>
      </div>
    </div>
  );
};
