/**
 * Company: SPHMedia
 * Description: [Hide prestitial overlay]
 */
import { useEffect } from "react";

/**
 * Custom React Hook: useRemoveOverlayHook
 * Description: [Hide prestitial overlay, use when not using BHBasePaage]
 */
export default function useRemoveOverlay() {
  useEffect(() => {
    if (typeof document !== "undefined") {
      const overlay = document.getElementById("MyPageOverlay");
      if (overlay != null) {
        overlay.style.display = "none";
      }
    }
  }, []);
}
