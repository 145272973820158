import { ZODIACS } from "../Blocks/Horoscope/constants";

type PageDetailsType = {
  url: string;
  label: string;
  hasDesktopNav: boolean;
}[];

const ZodiacDetails: PageDetailsType = ZODIACS.map((zodiac) => ({
  url: `horoscope-${zodiac.en}`,
  label: zodiac.ta,
  hasDesktopNav: false,
}));

const PageDetails: PageDetailsType = [
  {
    url: "/",
    label: "Home",
    hasDesktopNav: true,
  },
  {
    url: "recent-articles",
    label: "அண்மைய",
    hasDesktopNav: true,
  },
  {
    url: "maanavar-murasu",
    label: "மாணவர் முரசு",
    hasDesktopNav: false,
  },
  {
    url: "singapore",
    label: "சிங்க‌ப்பூர்",
    hasDesktopNav: true,
  },
  {
    url: "tamilnadu",
    label: "தமிழ்நாடு",
    hasDesktopNav: true,
  },
  {
    url: "india",
    label: "இந்தியா",
    hasDesktopNav: true,
  },
  {
    url: "world",
    label: "உல‌க‌ம்",
    hasDesktopNav: true,
  },
  {
    url: "community",
    label: "சமூகம்",
    hasDesktopNav: true,
  },
  {
    url: "videos",
    label: "காணொளிகள்",
    hasDesktopNav: true,
  },
  {
    url: "podcasts",
    label: "வலையொளி",
    hasDesktopNav: true,
  },
  {
    url: "lifestyle",
    label: "வாழ்வும் வளமும்",
    hasDesktopNav: true,
  },
  {
    url: "cinema",
    label: "திரைச்செய்தி",
    hasDesktopNav: true,
  },
  {
    url: "sports",
    label: "விளையாட்டு",
    hasDesktopNav: true,
  },
  {
    url: "special-feature",
    label: "சிறப்புக் கட்டுரை",
    hasDesktopNav: true,
  },
  {
    url: "literature",
    label: "கதை/கவிதை",
    hasDesktopNav: true,
  },
  {
    url: "youth",
    label: "இளையர் முரசு",
    hasDesktopNav: true,
  },
  {
    url: "editorial",
    label: "தலையங்கம்",
    hasDesktopNav: true,
  },
  {
    url: "top-news",
    label: "தலைப்புச் செய்தி",
    hasDesktopNav: true,
  },
  {
    url: "photo-gallery",
    label: "படங்களில் இந்த வார செய்தி",
    hasDesktopNav: true,
  },
  {
    url: "jobs",
    label: "All Jobs Page",
    hasDesktopNav: true,
  },
  {
    url: "balar-murasu",
    label: "Preschooler Murasu",
    hasDesktopNav: false,
  },
  {
    url: "newsletter-signup",
    label: "Newsletter Signup",
    hasDesktopNav: true,
  },
  ...ZodiacDetails,
];

export default function (sectionName?: string) {
  return PageDetails.find((page) => page.url === sectionName);
}
