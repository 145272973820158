import { NeuronTracker, useNeuron } from "@sphtech/neuron";
import { ReactElement, ReactNode } from "react";
import { useEffect, useState } from "react";

type Props = {
  children: ReactNode;
};

export default function TrackerProvider({ children }: Props): ReactElement {
  const { track } = useNeuron() as NeuronTracker;
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined" && !isLoaded) {
      track("load");
      track("scrollDepth");
      setIsLoaded(true);
    }
  }, [track, isLoaded]);

  return <>{children}</>;
}
