import VideoCard from "@components/Card/VideoCard";
import VideoDialog from "@elements/Player/VideoDialog";
import useSwrApi from "@hooks/useSwrApi";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { useState } from "react";

export default function LatestVideo() {
  const { isScreenLG } = useWindowSize();
  const videoCount = isScreenLG ? 6 : 1;

  const { data: videos } = useSwrApi<LatestVideoResponse[]>("latest-videos");

  const [videoDialog, setVideoDialog] = useState({
    visible: false,
    source: "",
  });

  const openVideoDialog = (source?: string) => {
    setVideoDialog({
      visible: true,
      source: source || "",
    });
  };

  const closeVideoDialog = (source?: string) => {
    setVideoDialog({
      visible: false,
      source: source || "",
    });
  };

  return (
    <>
      {videos ? (
        <>
          <VideoCard<LatestVideoResponse>
            renderedVideos={videos.slice(0, videoCount)}
            handleOnClickCallback={openVideoDialog}
          />
          <VideoDialog
            videoDialog={videoDialog}
            handleOnClickCloseDialog={closeVideoDialog}
          />
        </>
      ) : null}
    </>
  );
}
