import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function VideoMetaData(section: string): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: section,
    title: `${capitalize(section)}_Index`,
    keyword: section,
    contentcat: 1,
    contenttype: undefined,
  };

  const metaTags: MetaTagsProps = {
    title:
      "காணொளிகள் - Watch Tamil News Videos: Latest Headlines & Breaking Stories | Tamil Murasu",
    description:
      "காணொளிகள் | Watch latest Tamil News Videos: Singapore, India, Politics, Entertainment & Sports Updates. தமிழ் செய்தி காணொளிகள்: சிங்கப்பூர், இந்தியா, அரசியல், பொழுதுபோக்கு & விளையாட்டு செய்திகள்.",
    slug: section,
    keywords: [
      "தமிழ் காணொளிகள்",
      "இன்றைய காணொளிகள்",
      "தமிழ் வீடியோக்கள்",
      "Latest Tamil Videos",
      "Latest News Videos",
      "Videos",
    ],
  };

  return {
    gaData,
    metaTags,
  };
}
