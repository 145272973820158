import ArticlePage from "@pages/Article/Article.tsx";
import AuthorPage from "@pages/Author/AuthorPage";
import HomePage from "@pages/Home/Home.tsx";
import Horoscope from "@pages/Horoscope/Horoscope";
import KeywordPage from "@pages/Keyword/Keyword";
import Newsletter from "@pages/Newsletter/Newsletter";
import PhotoGalleryPage from "@pages/PhotoGallery/PhotoGallery.tsx";
import PhotoGalleryDetailPage from "@pages/PhotoGallery/PhotoGalleryDetailPage.tsx";
import PodcastPage from "@pages/Podcast/Podcast";
import RecentArticlesPage from "@pages/RecentArticles/RecentArticles.tsx";
import SectionPage from "@pages/Section/Section.tsx";
import VideoPage from "@pages/Video/Video";
import { AuthCallback } from "@sphtech/web2-core/auth";
import { fetchContext, ResponseType } from "@sphtech/web2-core/ssr";
import { RouteObject } from "react-router-dom";

import {
  getClientId,
  getIssuer,
} from "../../.web2/web2-helpers/auth/MySphConfig";
import App from "./App";
import { SearchResultPage } from "./pages/Search/SearchResult";
import StatusHandler from "./pages/StatusHandler/StatusHandler";

const errorElement = import.meta.env.PROD ? (
  <StatusHandler clientSideError />
) : undefined;

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage />,
        errorElement,
        loader: fetchContext,
      },
      /**
       * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */

      {
        path: "/oauth/callback",
        element: <AuthCallback clientId={getClientId()} issuer={getIssuer()} />,
        errorElement,
      },
      {
        path: "/horoscope/:zodiac",
        element: <Horoscope />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/article/:id",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/apple-app-site-association",
        element: <></>,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/.well-known/assetlinks.json",
        element: <></>,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/videos",
        element: <VideoPage />,
        errorElement,
        loader: fetchContext,
      },
      // {
      //   path: "/:section/:second/:slug",
      //   element: <ArticlePage />,
      // errorElement,
      //   loader: fetchContext,
      // },
      {
        path: "/podcasts",
        element: <PodcastPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/podcasts/:level1",
        element: <PodcastPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/recent-articles",
        element: <RecentArticlesPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/photo-gallery",
        element: <PhotoGalleryPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/photo-gallery/:slug",
        element: <PhotoGalleryDetailPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/keywords/:level1",
        element: <KeywordPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/author/:level1",
        element: <AuthorPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/search/site/:searchValue",
        element: <SearchResultPage />,
        loader: fetchContext,
      },
      {
        path: "/:level1",
        element: <SectionPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:section/:slug",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      // {
      //   path: "/:level1/:level2",
      //   element: <SectionPage />,
      // errorElement,
      //   loader: fetchContext,
      // },
      {
        path: "/newsletter/sign-up",
        element: <Newsletter />,
        errorElement,
      },
      {
        path: "*",
        element: (
          <StatusHandler
            response={{
              type: ResponseType.CLIENT_ERROR,
              statusCode: 404,
            }}
          />
        ),
        errorElement,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
