export type IframeWrapperAttr = {
  className?: string;
};

export type IframeProps = {
  src: string;
  height?: string;
  width?: string;
  title?: string;
  className?: string;
  allowFullScreen?: boolean;
  addWrapper?: boolean;
  wrapperAttr?: IframeWrapperAttr;
};

export default function Iframe({
  src,
  height = "349",
  width = "620",
  title = "#NoTapis",
  className,
  allowFullScreen = false,
  addWrapper = false,
  wrapperAttr = {},
}: IframeProps) {
  const iframe = (
    <iframe
      className={className}
      src={src}
      width={width}
      height={height}
      title={title}
      allowFullScreen={allowFullScreen}
    ></iframe>
  );

  return addWrapper ? (
    <div className={wrapperAttr.className}>{iframe}</div>
  ) : (
    iframe
  );
}
