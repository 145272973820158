/* eslint-disable no-useless-escape */
export default `var _comscore = _comscore || [];
    _comscore.push({c1: "2", c2: "6288331",
      options: {
        enableFirstPartyCookie: true
      }
    });
    (function () {
        var s = document.createElement("script"), el = document.getElementsByTagName("script")[0];
        s.async = true;
        s.src = "https://sb.scorecardresearch.com/cs/6288331/beacon.js";
        el.parentNode.insertBefore(s, el);
    })();`;
