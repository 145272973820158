import { IframeProps } from "@elements/Iframe/Iframe";
import { isThisUrlFrom } from "@helper/utils";

export default function IframeAttr(source: string): IframeProps {
  if (isThisUrlFrom(source, "omny")) {
    return {
      src: source,
      width: "100%",
      height: "180px",
    };
  }

  if (isThisUrlFrom(source, "dugout")) {
    return {
      src: decodeURIComponent(source),
      width: "100%",
      height: "100%",
      className: "absolute",
      addWrapper: true,
      wrapperAttr: {
        className: "relative pb-[60%]",
      },
      allowFullScreen: true,
    };
  }

  return {
    src: source,
  };
}
