import "./Embeds.css";

import { FB_APP_ID } from "@components/Elements/MetaTags/MetaTagsConstants";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { EmbeddedPost, FacebookProvider } from "react-facebook";

import WrapperContainer from "../ParagraphElements/WrapperContainer";
import { SOCIAL_EMBED_WIDTH } from "./constants";

export type FacebookEmbedCustomProps = {
  url: string;
  className?: string;
};

export default function FacebookEmbedCustom({
  url,
  className = "",
}: FacebookEmbedCustomProps): React.ReactElement {
  const { isScreenSM } = useWindowSize();

  return (
    <FacebookProvider appId={FB_APP_ID}>
      <WrapperContainer className={className}>
        <EmbeddedPost
          href={url}
          width={isScreenSM ? SOCIAL_EMBED_WIDTH : "auto"}
          lazy
          showText
        />
      </WrapperContainer>
    </FacebookProvider>
  );
}
