import multiImage from "@assets/actions/multi-image.svg";

export type ArticleCarouselFooterProps = {
  caption: string;
  credit: string;
  currentSlideCount?: number;
  totalSlideCount?: number;
};

export default function ArticleCarouselFooter({
  caption,
  credit,
  currentSlideCount,
  totalSlideCount,
}: ArticleCarouselFooterProps) {
  const currentSlide = currentSlideCount ?? 1;
  const totalSlides = totalSlideCount ?? 1;

  return (
    <div className="inline-flex gap-2 bg-red-400 p-2 w-full">
      <span className="w-full items-center left-0 text-gray-100 font-primary text-xs font-normal leading-250">
        {`${caption} - `} <span className="font-bold">{credit}</span>
      </span>

      {totalSlides > 1 ? (
        <div
          className="flex w-20 items-start font-primary font-bold text-grey-700"
          style={{ zIndex: 1 }}
        >
          <img
            src={multiImage}
            alt="multi-img"
            className="!hidden lg:!block mr-2"
            width={20}
            height={20}
          />
          <span>
            {currentSlide + 1} <span className="text-gray-100">of</span>{" "}
            {totalSlides}
          </span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
