export default function FestivalBanner() {
  return (
    <>
      <div className="festival-bg xl:max-w-[1440px] w-full mx-auto lg:p-0 flex items-center justify-center">
        <div className="festival-text text-white-100 text-center">
          <p>
            தமிழ் முரசு வாசகர்களுக்கு எங்கள் உளங்கனிந்த தீபாவளி வாழ்த்துகள்!
          </p>
        </div>
      </div>
    </>
  );
}
