import BmMenuLogo from "@assets/balarmurasu/bm_header_menu.svg";
import EpaperMenuLogo from "@assets/epaper/ic-pdf.svg";
import MmMenuLogo from "@assets/manavar_murasu/mm_topheader_logo.svg";
import { EPAPER_LINK } from "@helper/getEnvVariables";
import { isProd } from "@helper/utils";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

import MySphLoginButton from "./MySPHLoginButton";
import UserInfo from "./UserInfo/UserInfo";

export function TopHeaderMenues(): ReactElement {
  const { userInfo } = useUserInfo();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  return (
    <>
      <div className="pr-sm pt-2">
        <Link
          to="/balar-murasu"
          target="_blank"
          rel="noreferrer"
          reloadDocument
        >
          <img
            width={115}
            height={20}
            src={BmMenuLogo}
            className="h-5 w-auto"
            alt="balar-murasu"
          />
        </Link>
      </div>
      <span className="border-l border-solid border-gray-400 mt-100 h-8"></span>
      <div className="px-sm pt-1">
        <Link
          reloadDocument
          to="/maanavar-murasu"
          rel="noreferrer"
          className="inline-flex text-red-100 hover:text-red-200 hover:underline font-bold text-sm"
        >
          <img
            width={22}
            height={24}
            src={MmMenuLogo}
            className="pr-1 h-xm pt-4xs"
            alt="manavar-murasu"
          />
          மாணவர் முரசு
        </Link>
      </div>
      <span className="border-l border-solid border-gray-400 mt-100 h-8"></span>
      <div className="pr-3.5 pl-4 pt-1.5">
        <Link
          reloadDocument
          to={EPAPER_LINK}
          target="_blank"
          rel="noreferrer"
          className="inline-flex text-red-100 hover:text-red-200 hover:underline font-bold tracking-[0.5px]"
        >
          <img
            width={27}
            height={20}
            src={EpaperMenuLogo}
            className="pr-1 h-5 mt-0.5"
            alt="epaper"
          />
          இ-பேப்பர்
        </Link>
      </div>

      <span className="border-l border-solid border-gray-400 mt-100 h-8"></span>
      {!isProd && (
        <>
          {userDisplayName ? (
            <UserInfo
              userInfo={
                {
                  ...userInfo,
                  lastname: userDisplayName,
                  loginId: OKTAUserInfo.loginid,
                  sub: OKTAUserInfo.reguserstatus,
                } as UserInfoType
              }
            />
          ) : (
            <MySphLoginButton />
          )}
        </>
      )}
    </>
  );
}
