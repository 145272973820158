import footerPhoneIcon from "@assets/footer/phone-icon.svg";
import { Link } from "react-router-dom";

type FooterAdvertiseProps = {
  testId?: string;
};

export function FooterAdvertise({ testId }: FooterAdvertiseProps) {
  return (
    <div
      data-testid={`${testId}-advertise`}
      className="md:float-right lg:px-3.5 xs:py-xs lg:pb-xs md:px-sm text-2sm not-italic leading-400 tracking-normal text-gray-100 md:w-4/12 font-sans"
    >
      <span>For Advertising: </span>
      <Link
        className="text-red-100 hover:text-red-200 hover:underline"
        to="https://www.sph.com.sg/contact-us/?ctopic=advertising&csubject=general%20advertising"
      >
        Contact Us
      </Link>
      <div className="py-xs">
        <div className="lg:text-xs text-xxs">
          <img
            height={20}
            width={20}
            alt="footer-phone-icon-1"
            src={footerPhoneIcon}
            className="inline"
          />{" "}
          : Mr.Kalim: (65) 98633894{" "}
        </div>
        <div className="lg:text-xs text-xxs">
          <img
            height={20}
            width={20}
            alt="footer-phone-icon-2"
            src={footerPhoneIcon}
            className="inline"
          />{" "}
          : Mrs.Maheshwari: (65) 9366 5277
        </div>
      </div>
      <div className="text-2sm leading-400">
        Explore advertising opportunities with{" "}
        <Link
          className="text-red-100 hover:text-red-200 hover:underline"
          to="https://www.sph.com.sg/advertising-solutions/"
        >
          SPH Media
        </Link>
        .
      </div>
    </div>
  );
}
