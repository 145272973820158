import Cookies from "js-cookie";
import { useEffect, useState } from "react";

export default function Outbrain({
  pathName,
  version,
}: {
  pathName: string;
  version?: string;
}) {
  const outbrainVersion =
    version ??
    (typeof window !== "undefined" && screen.width <= 767 ? "AR_2" : "AR_1");
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (typeof window !== "undefined" && !isLoaded) {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      if (typeof window._data != "undefined") {
        Cookies.set("outbrain_enable", "1");
      } else {
        Cookies.set("outbrain_enable", "0");
      }
      setIsLoaded(true);
    } else {
      const { OBR } = window;
      if (
        OBR &&
        OBR.extern &&
        typeof OBR.extern.researchWidget === "function"
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        OBR.extern.researchWidget();
      }
    }
  }, [isLoaded]);
  return (
    <div
      className="OUTBRAIN [&_.ob-widget-header]:!text-black-100 [&_.ob-rec-source]:!text-black-100"
      data-src={`https://www.tamilmurasu.com.sg/${pathName}`}
      data-widget-id={outbrainVersion}
      data-ob-template="TamilMurasu"
    ></div>
  );
}
