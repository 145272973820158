import Iframe from "@elements/Iframe/Iframe";

import IframeAttr from "./helper/IframeAttr";
import SocialEmbedFactory from "./helper/SocialEmbedFactory";

export default function Embed({
  src,
  className,
}: {
  src: string;
  className?: string;
}): React.ReactElement {
  const SocialEmbed = SocialEmbedFactory(src);
  const iframeAttr = IframeAttr(src);

  return (
    <div className={className} data-testid="embed-component">
      {SocialEmbed ? <SocialEmbed url={src} /> : <Iframe {...iframeAttr} />}
    </div>
  );
}
