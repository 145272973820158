import PlayButton from "@assets/actions/play-button.svg";
import { Image } from "@elements/ResponsiveImage/Image";

import Heading from "../Elements/Typography/Heading";

interface ThumbnailItem {
  formattedTime?: string;
  name?: string;
  thumbnail: string;
  src?: string;
  id?: string;
}
export type VideoCardProps<T> = {
  renderedVideos?: (T & ThumbnailItem)[];
  handleOnClickCallback: (source?: string) => void;
};

export default function VideoCard<T>({
  renderedVideos,
  handleOnClickCallback,
}: VideoCardProps<T>) {
  return (
    <div className="lg:border-t border-solid border-pink-300 lg:mb-6 lg:pb-5">
      <Heading Element="h6" className="font-primary font-bold text-md my-4">
        <a href="/videos" className="text-red-100">
          <span className="hover:underline">அண்மைய காணொளிகள்</span>
          <span className="ml-xxs font-bold">{">"}</span>
        </a>
      </Heading>

      <div className="lg:grid lg:gap lg:lg:gap-x-lg lg:gap-y-[19px] lg:m-0 lg:grid-cols-3 mt-5">
        {renderedVideos &&
          renderedVideos.map((video, index) => (
            <button
              className="h-full lg:max-w-max flex"
              key={index}
              onClick={() => {
                handleOnClickCallback(video.src ? video.src : video.id);
              }}
            >
              <div className="block">
                <div className="relative inline-flex">
                  <span
                    className="text-white-100 bg-black-100 text-xs bg-opacity-70 absolute z-1 right-0 bottom-0 py-0.5 px-xxs"
                    data-testid="time"
                  >
                    {video.formattedTime}
                  </span>
                  <div className="flex items-center w-full">
                    <Image
                      className="w-[70px] h-[70px] absolute left-[50%] -ml-400"
                      src={PlayButton}
                      alt={""}
                      srcWidth={70}
                      srcHeight={70}
                    />
                  </div>
                  <Image
                    objectFit="cover"
                    className="w-[335px] h-[600px] lg:w-[235px] lg:h-[235px]"
                    src={video.thumbnail}
                    srcWidth={235}
                    srcHeight={235}
                    alt="brightcove video"
                    dataTestId="image"
                  />
                </div>
                <div className="text-left mt-2.5 mb-5 relative text-gray-100 font-semibold text-2sm leading-430 px-1.5">
                  {video.name}
                </div>
              </div>
            </button>
          ))}
      </div>
    </div>
  );
}
