import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import Heading from "@elements/Typography/Heading";
import fetchTMApi from "@helper/fetchTMApi";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { ReactElement, useEffect, useRef, useState } from "react";

type LatestVideoPlayerProps = {
  type?: string;
  size?: number;
  offset?: number;
  className?: string;
};
export default function LatestVideoPlayer({
  type,
  size = 1,
  offset,
  className,
}: LatestVideoPlayerProps): ReactElement {
  const [playing, setPlaying] = useState<LatestVideoResponse | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      const videos = await fetchTMApi<LatestVideoResponse[]>(
        `single-video/${size}/${offset}/${type}`,
        "GET",
      );
      setPlaying(videos[0]);
    };
    void fetchData();
  }, [offset, size, type]);

  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <>
      {playing?.id && (
        <div className="border-t border-pink-300 pt-6">
          <Heading Element="h6" className="font-primary font-bold text-md mb-4">
            <a href="/videos" className="text-red-100">
              <span className="hover:underline">அண்மைய காணொளிகள்</span>
              <span className="ml-5 font-bold">{">"}</span>
            </a>
          </Heading>
          <BrightcoveVideo
            videoId={playing.id}
            forwardedRef={containerRef}
            className={className}
          />
          <div className="text-md font-semibold font-primary leading-650 mt-2">
            {playing.name}
          </div>
        </div>
      )}
    </>
  );
}
