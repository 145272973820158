import fetchCaas from "@helper/fetchCaas";
import fetchTMApi from "@helper/fetchTMApi";
import { removeTrailingSlash } from "@helper/removeTrailingLeadingSlash";
import { ArticlePageQuery, ArticlePageQueryByUrl } from "@query/article";
import { RelatedStoriesQuery } from "@query/query";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { UrlMatch } from "@src/app/data";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOsResponse";
import { ArticleRouteParam, RouteParam } from "@typings/Data";
import { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type { OSResponse, PartialCueArticle } from "@typings/Opensearch";
import { Match, MatchResult } from "path-to-regexp";

export const isMatchResult = (
  a: Match<RouteParam>,
): a is MatchResult<ArticleRouteParam> => {
  return "id" in ((a as MatchResult<ArticleRouteParam>).params as RouteParam);
};

export type PayloadResponseType = {
  article: ProcessedArticleData;
  relatedStories: ProcessedArticleData[];
};

export const fetchArticleData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<PayloadResponseType | null, string>
> => {
  const id = isMatchResult(urlComponent) ? urlComponent.params.id : undefined;

  let articleQuery;
  if (!id) {
    const urlPath = removeTrailingSlash(urlComponent.path);
    articleQuery = ArticlePageQueryByUrl(urlPath);
  } else {
    articleQuery = ArticlePageQuery(id);
  }

  try {
    const data = (await fetchCaas({
      searchQuery: articleQuery,
    })) as OSResponse<PartialCueArticle> | null;

    const articlePayload =
      data?.payload.hits.hits[0]?._source.data.context ?? null;

    if (articlePayload == null || data == null) {
      return {
        type: ResponseType.CLIENT_ERROR,
        statusCode: 404,
        payload: "Article not found",
      };
    }

    const related_stories_ids = getOSProcessedArticleData(data)[0][
      "relatedArticles"
    ].map((story) => story.id);
    const relatedArticleLoad = (await fetchCaas({
      searchQuery: RelatedStoriesQuery(related_stories_ids),
    })) as OSResponse<PartialCueArticle>;

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        article: getOSProcessedArticleData(data)[0],
        relatedStories: getOSProcessedArticleData(relatedArticleLoad),
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchNextStoryData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[]> {
  const response: ProcessedArticleData[] = await fetchTMApi(
    "get-article",
    "POST",
    {
      section: requestData.filterArticlesBasedOn,
      excludeIds: requestData.excludeIds,
    },
  );
  return response;
}

export async function fetchNextStoryRelatedArticleData(
  requestData: string[],
): Promise<ProcessedArticleData[]> {
  const response: ProcessedArticleData[] = await fetchTMApi(
    "get-related-article",
    "POST",
    {
      RelatedStoryIds: requestData,
    },
  );
  return response;
}
