import SPHMediaLogoFooter from "@assets/SPHMediaLogo.svg";
import TMMobileLogoFooter from "@assets/tm_logo_mobile.svg";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

import { FooterAdvertise } from "./FooterAdvertise";
import { FooterCopyright } from "./FooterCopyright";
import { FooterLinks } from "./FooterLinks";
import { FooterNewsletterSignup } from "./FooterNewsletterSignup";

export type FooterProps = {
  testId?: string;
};

export function Footer({ testId = "footer" }: FooterProps): ReactElement {
  return (
    <>
      <footer className="bg-red-400 mt-500 max-w-full px-2 lg:px-0">
        <FooterNewsletterSignup />
        <div className="flex">
          <div
            data-testid={`${testId}-footerContainer`}
            className="xl:max-w-xl lg:max-w-lg md:max-w-xm sm:max-w-xs w-full mx-auto py-0 lg:py-4 xs:px-sm xl:px-xxs font-primary border-gray-2200 border-t max-xs:px-xxs max-xs:py-4"
          >
            <div className="inline-block md:w-8/12">
              <div className="xs:my-5 lg:my-md flex">
                <Link reloadDocument to="/" rel="home" title="Tamil Murasu">
                  <img
                    height="30px"
                    width="144px"
                    className="block"
                    alt="Home"
                    src={TMMobileLogoFooter}
                  />
                </Link>
                <span className="border-l border-solid border-gray-400 mx-xs"></span>
                <Link to="https://www.sph.com.sg/" rel="home" title="Home">
                  <img
                    height="47px"
                    width="139px"
                    alt="Home"
                    src={SPHMediaLogoFooter}
                  />
                </Link>
              </div>
              <FooterCopyright testId={testId} />
              <FooterLinks testId={testId} />
            </div>
            <FooterAdvertise testId={testId} />
          </div>
        </div>
      </footer>
    </>
  );
}
