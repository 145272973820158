import DefaultImage from "@assets/tm_default_image.webp";
import { type RawImageField } from "@src/app/types/Opensearch";
import { twMerge } from "tailwind-merge";

import Date from "../Elements/Typography/Date";
import Heading from "../Elements/Typography/Heading";
import Image from "../ResponsiveImage/Image";

export type PodcastCardElementProps = {
  hasParagraph?: boolean;
  hasDate?: boolean;
};

export type PodcastCardStyleProps = {
  cardWrapper?: string;
  contentWrapper?: string;
  paragraphStyle?: string;
  titleWrapper?: string;
  titleStyle?: string;
  imageWrapperStyle?: string;
  imageStyle?: string;
};

export type PodcastCardProps = {
  linkTo: string;
  title: string;
  paragraph?: string;
  fullDate?: string;
  time?: string;
  displayWidth: number;
  displayHeight: number;
  imageField?: RawImageField;
  imageAlt?: string;
  urlPath?: string;
  overrideStyle?: PodcastCardStyleProps;
  elements?: PodcastCardElementProps;
};

export default function PodcastCard({
  title,
  paragraph,
  fullDate,
  time,
  displayWidth,
  imageField,
  imageAlt,
  overrideStyle,
  elements,
}: PodcastCardProps) {
  const {
    cardWrapper,
    contentWrapper,
    imageWrapperStyle,
    imageStyle,
    paragraphStyle,
    titleStyle,
    titleWrapper,
  } = overrideStyle || {};
  const { hasParagraph = true, hasDate } = elements || {};
  return (
    <div
      className={twMerge(
        "flex flex-row w-full gap-sm py-5 border-b border-pink-300",
        cardWrapper,
      )}
    >
      <div
        className={twMerge(
          "flex-shrink-0 md:flex-grow-0 w-[90px] lg:w-auto h-[90px] sm:w-[260px] lg:h-auto",
          imageWrapperStyle,
        )}
      >
        <Image
          displayWidth={displayWidth}
          srcWidth={160}
          srcHeight={160}
          src={imageField?.url ?? DefaultImage}
          alt={imageAlt ?? title}
          className={twMerge("w-[120px] lg:w-[160px]", imageStyle)}
        />
      </div>

      <div
        className={twMerge(
          "flex flex-col w-full text-start leading-400 gap-xs",
          contentWrapper,
        )}
      >
        <div className={twMerge("flex flex-col-reverse gap-xs", titleWrapper)}>
          <Heading
            Element="h2"
            className={twMerge(
              "font-bold font-primary hover:underline text-md text-black-300 hover:text-black-300",
              titleStyle,
            )}
          >
            {title}
          </Heading>
        </div>

        {hasParagraph && (
          <p
            className={twMerge(
              "font-regular font-primary text-xs lg:text-xs leading-300",
              paragraphStyle,
            )}
          >
            {paragraph}
          </p>
        )}

        {hasDate && (
          <Date>
            {fullDate}
            {time ? <span className="lowercase"> - {time}</span> : ""}
          </Date>
        )}
      </div>
    </div>
  );
}
