import {
  type ProcessedArticleBody,
  type ProcessedFullParagraph,
} from "@typings/Opensearch";

export default function GetLimitedParagraphsFromArticleBody(
  articleBody: ProcessedArticleBody,
  maxParagraphs: number,
): ProcessedArticleBody {
  return articleBody
    .filter((element): element is ProcessedFullParagraph => {
      return element.type === "paragraph";
    })
    .slice(0, maxParagraphs);
}
