import { useCallback, useEffect, useRef, useState } from "react";

type DataWrapperMessage = {
  "datawrapper-height"?: { [key: string]: number };
};

export default function DataWrapperEmbed({ url }: { url: string }) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(500);

  const onMessage = useCallback(
    ({ data = {}, source }: MessageEvent<DataWrapperMessage>) => {
      if (
        source !== iframeRef.current?.contentWindow ||
        typeof data === "string" ||
        !data["datawrapper-height"]
      ) {
        return;
      }

      const actualHeight = Object.values(data["datawrapper-height"])[0];

      if (actualHeight && typeof actualHeight === "number") {
        setHeight(actualHeight);
      }
    },
    [iframeRef],
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  return (
    <div className="embed-wrapper">
      <iframe
        ref={iframeRef}
        data-height={height}
        src={url}
        style={{
          width: "100%",
          height,
          border: 0,
          boxSizing: "content-box",
        }}
        data-external="1"
        title="data-wrapper-title"
      ></iframe>
    </div>
  );
}
