import { useEffect, useRef } from "react";

type IntersectionObserverCallback = (element: Element) => void;

export default function useIntersectionObserver(
  callback: IntersectionObserverCallback,
  options?: IntersectionObserverInit,
) {
  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry.target);
        }
      });
    }, options);

    return () => {
      observer.current?.disconnect();
    };
  }, [callback, options]);

  const observe = (element: Element | null) => {
    if (observer.current && element) {
      observer.current.observe(element);
    }
  };

  const unobserve = (element: Element | null) => {
    if (observer.current && element) {
      observer.current.unobserve(element);
    }
  };

  return { observe, unobserve };
}
