import { AdvertisementTypeEnum } from "@typings/Ads.d";
import { useDetectAdBlock } from "adblock-detect-react";
import cx from "classnames";
import Cookies, { CookieAttributes } from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import { adSlotSizes, adTargetings } from "./AdsConstants";
import styles from "./Advertisement.module.css";
import { generateAdvertElementId, useAdvertLoaded } from "./helpers";

const TYPE = AdvertisementTypeEnum.PRESTITIAL;
const ID = generateAdvertElementId(TYPE);
const TOPOVERLAY_IMPRESSIONS = 3;
const TOPOVERLAY_VALIDITY = 4;

export type PrestitialAdProps = {
  rootClassName?: string;
  slot: string;
  isPrestitialEnabled: boolean | undefined;
};

/**
 * This component renders an Prestitial ad.
 * @param rootClassName - The root class name.
 * @param slot - The ad slot.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A Prestitial ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export default function PrestitialAd({
  rootClassName,
  slot,
  isPrestitialEnabled,
}: PrestitialAdProps) {
  const topoverlayDisplayedCookie = Cookies.get("topoverlayDisplayed");
  const topOverlayImpressionsServedCookie = Cookies.get(
    "topOverlayImpressionsServed",
  );
  let impressionsServed = topOverlayImpressionsServedCookie
    ? parseInt(topOverlayImpressionsServedCookie)
    : 0;

  const isAdLoaded = useAdvertLoaded(TYPE, ID);
  const [error, setError] = useState("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();
  const adSlotSize = adSlotSizes[TYPE];
  const adTarget = adTargetings[TYPE];
  const adBlockDetected = useDetectAdBlock();

  const handleDisplayAd = useCallback(() => {
    try {
      googletag.cmd.push(function () {
        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot) {
            if (_slot.getSlotElementId()) {
              if (_slot.getSlotElementId() === ID) {
                googletag.destroySlots([_slot]);
              }
            }
          });

        const _adSlot = googletag.defineSlot(slot, adSlotSize, ID);

        if (_adSlot) {
          _adSlot.addService(googletag.pubads());

          adTarget?.forEach(({ key, value }) => {
            _adSlot.setTargeting(key, value);
          });
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.googletag.slots["prestitial"] = _adSlot;

          googletag.pubads().enableSingleRequest();
          googletag.enableServices();

          // Store the ad slot for later use.
          setAdSlot(_adSlot);
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [slot, adSlotSize, adTarget]);

  const closeOverlay = useCallback(() => {
    if (typeof document !== "undefined") {
      document.body.style.overflowY = "visible";
      document.documentElement.style.overflowY = "visible";
      const myPageOverlay = document.getElementById("MyPageOverlay");
      if (myPageOverlay != null) {
        myPageOverlay.style.display = "none";
      }
    }
  }, []);

  const isDFPTest = useCallback(() => {
    const hasDFPTest =
      window.dfp_preview_ids !== undefined &&
      Array.isArray(window.dfp_preview_ids) &&
      window.dfp_preview_ids.length !== 0;

    return Boolean(hasDFPTest);
  }, []);

  useEffect(() => {
    window.adBlockerDetected = adBlockDetected;
    if (
      typeof window !== "undefined" &&
      isPrestitialEnabled === true &&
      !adBlockDetected
    ) {
      if (slot && !adSlot) {
        if (
          ((impressionsServed < TOPOVERLAY_IMPRESSIONS &&
            typeof topoverlayDisplayedCookie === "undefined") ||
            isDFPTest()) &&
          screen.width > 767
        ) {
          handleDisplayAd();

          const expiry = new Date();
          expiry.setTime(
            expiry.getTime() + TOPOVERLAY_VALIDITY * 60 * 60 * 1000,
          );
          const cookieOptions: CookieAttributes = {
            path: "/",
            expires: expiry,
          };

          if (!isDFPTest()) {
            impressionsServed++;
            Cookies.set("topoverlayDisplayed", "yes", cookieOptions);
            Cookies.set(
              "topOverlayImpressionsServed",
              impressionsServed === TOPOVERLAY_IMPRESSIONS
                ? "0"
                : impressionsServed.toString(),
              cookieOptions,
            );
          }
        } else {
          closeOverlay();
        }
      }
    } else {
      if (
        isPrestitialEnabled === false ||
        isPrestitialEnabled == undefined ||
        adBlockDetected
      ) {
        closeOverlay();
      } else {
        const timeout = setTimeout(() => {
          closeOverlay();
        }, 3000);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [
    slot,
    handleDisplayAd,
    adSlot,
    impressionsServed,
    topoverlayDisplayedCookie,
    adBlockDetected,
    isPrestitialEnabled,
    closeOverlay,
    isDFPTest,
  ]);

  useEffect(() => {
    return () => {
      const gTag = window.googletag as typeof googletag | undefined;
      if (gTag && gTag.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  return (
    <>
      <div className={cx(rootClassName, !isAdLoaded && styles.invisible)}>
        {!error ? <div id={ID}></div> : error}
      </div>
    </>
  );
}
