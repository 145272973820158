import OutbrainMainContainer, {
  AdsSettings,
} from "@components/Container/OutbrainMainContainer";
import WrapperBlock from "@container/WrapperBlock";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { CustomContext, useRouteContext } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { CinemaArticles } from "@src/app/components/Blocks/Home/CinemaArticles";
import { HoroscopeSection } from "@src/app/components/Blocks/Home/HoroscopeSection";
import { JobsListing } from "@src/app/components/Blocks/Home/JobsListing";
import { MovieTimingsSection } from "@src/app/components/Blocks/Home/MovieTimingsSection";
import { RecentArticlesSection } from "@src/app/components/Blocks/Home/RecentArticlesSection";
import { SectionArticles } from "@src/app/components/Blocks/Home/SectionArticles";
import { TopNewsSection } from "@src/app/components/Blocks/Home/TopNewsSection";
import { VideoPlaylistSection } from "@src/app/components/Blocks/Home/VideoPlaylistSection";
import PromoteEpaper from "@src/app/components/Blocks/PromoteEpaper/PromoteEpaper";
import MainBlock from "@src/app/components/Container/MainBlock";
import SideBlock from "@src/app/components/Container/SideBlock";
import { GoldSection } from "@src/app/components/Container/SideBlock/GoldSection";
import Divider from "@src/app/components/Elements/Divider/Divider";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";

import { HomePageDataPayload } from "./Home.server";
import HomeMetaData from "./HomeMetaData";

export default function HomePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<HomePageDataPayload, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const adSettings: AdsSettings = useGlobalAdSetting();
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    throw new Error("Client Error");
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    throw new Error("Server Error");
  }

  const { gaData, metaTags } = HomeMetaData();
  const pageAdTargetValue = PageAdTargetingTypeEnum.HOME;

  const sectionName = "/";
  const { width } = useWindowDimensions();
  const isMobile = width <= 480;

  const recentArticles = dataLoaderResponse.payload.recentArticles;
  const topNewsArticles = dataLoaderResponse.payload.topNewsArticles;

  return (
    <OutbrainMainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
      suppliedAdsSettings={adSettings}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock showBreadcrumbs={false} showTitle={false}>
          <>
            <TopNewsSection topNewsArticles={topNewsArticles} />
            {isMobile && (
              <PromoteEpaper className="lg:hidden border-t-0 pt-0" />
            )}
            <RecentArticlesSection recentArticles={recentArticles} />
            <VideoPlaylistSection />
            <SectionArticles />
            {isMobile && (
              <>
                {" "}
                <GoldSection />
                <Divider className="mt-5 border-b border-gray-2200" />
              </>
            )}
            <JobsListing />
            <HoroscopeSection />
            <MovieTimingsSection />
            <CinemaArticles />
          </>
        </MainBlock>
        <SideBlock
          sectionName="home"
          hasGold={!isMobile}
          hasForex
          hasOutbrain={adSettings.isOutbrainEnabled}
          pageAdTargetValue={pageAdTargetValue}
          promoteEpaper={!isMobile}
          hasTranslate
        />
      </WrapperBlock>
    </OutbrainMainContainer>
  );
}
