import BmMenuLogo from "@assets/balarmurasu/bm_header_menu.svg";
import EpaperMenuLogo from "@assets/epaper/ic-pdf.svg";
import MmMenuLogo from "@assets/manavar_murasu/mm_topheader_logo.svg";
import facebookLogo from "@assets/social_media/facebook.svg";
import instaLogo from "@assets/social_media/instagram.svg";
import twitterLogo from "@assets/social_media/twitter.svg";
import whatsappLogo from "@assets/social_media/whatsapp.svg";
import youtubeLogo from "@assets/social_media/youtube.svg";
import tablaLogo from "@assets/tabla/tabla_sm.webp";
import { EPAPER_LINK } from "@helper/getEnvVariables";

export type HeaderMenuChildrens = Readonly<{
  title?: string;
  url: string;
  newTab?: boolean;
}>;

export type HeaderMenuDetails = Readonly<{
  title?: string;
  url: string;
  images?: string;
  alt?: string;
  newTab?: boolean;
  child?: boolean;
  childrens?: HeaderMenuChildrens[];
  height?: number;
  width?: number;
}>;

export const HEADER_SOCIAL_MENU: HeaderMenuDetails[] = [
  {
    alt: "whatsapp",
    url: "https://whatsapp.com/channel/0029VadVjPV8fewnqkhfPd2X",
    images: whatsappLogo,
    newTab: true,
    height: 20,
    width: 20,
  },
  {
    alt: "facebook",
    url: "https://www.facebook.com/tamilmurasu/",
    images: facebookLogo,
    newTab: true,
    height: 20,
    width: 20,
  },
  {
    alt: "twitter",
    url: "https://twitter.com/tamilmurasu",
    images: twitterLogo,
    newTab: true,
    height: 20,
    width: 20,
  },
  {
    alt: "instagram",
    url: "https://www.instagram.com/tamil_murasu/",
    images: instaLogo,
    newTab: true,
    height: 20,
    width: 20,
  },
  {
    alt: "youtube",
    url: "https://www.youtube.com/channel/UCs0xZ60FSNxFxHPVFFsXNTA",
    images: youtubeLogo,
    newTab: true,
    height: 20,
    width: 20,
  },
];

export const HEADER_MAIN_MENU: HeaderMenuDetails[] = [
  {
    title: "அண்மைய",
    url: "/recent-articles",
  },
  {
    title: "சிங்க‌ப்பூர்",
    url: "/singapore",
  },
  {
    title: "தமிழ்நாடு",
    url: "/tamilnadu",
  },
  {
    title: "இந்தியா",
    url: "/india",
  },
  {
    title: "உல‌க‌ம்",
    url: "/world",
  },
  {
    title: "சமூகம்",
    url: "/community",
  },
  {
    title: "காணொளிகள்",
    url: "/videos",
  },
  {
    title: "வலையொளி",
    url: "/podcasts",
  },
  {
    title: "மற்றவை",
    url: "/",
    child: true,
    childrens: [
      {
        title: "வாழ்வும் வளமும்",
        url: "/lifestyle",
      },
      {
        title: "திரைச்செய்தி",
        url: "/cinema",
      },
      {
        title: "விளையாட்டு",
        url: "/sports",
      },
      {
        title: "சிறப்புக் கட்டுரை",
        url: "/special-feature",
      },
      {
        title: "கதை/கவிதை",
        url: "/literature",
      },
      {
        title: "இளையர் முரசு",
        url: "/youth",
      },
      {
        title: "படச் செய்திகள்",
        url: "/photo-gallery",
      },
      {
        title: "வேலைகள்",
        url: "/jobs",
      },
    ],
  },
  {
    height: 18,
    width: 50,
    alt: "tabla",
    url: "/tabla",
    images: tablaLogo,
  },
];

export const HEADER_TOP_MOBILE_MENU: HeaderMenuDetails[] = [
  {
    alt: "epaper",
    url: EPAPER_LINK,
    images: EpaperMenuLogo,
    width: 27,
    height: 20,
    newTab: true,
    title: "இ-பேப்பர்",
  },
  {
    alt: "manavar-murasu",
    url: "/maanavar-murasu",
    width: 22,
    height: 24,
    images: MmMenuLogo,
    newTab: false,
    title: "மாணவர் முரசு",
  },
  {
    alt: "balar-murasu",
    width: 115,
    height: 20,
    url: "/balar-murasu",
    images: BmMenuLogo,
    newTab: true,
  },
];
