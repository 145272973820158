import { UrlMatch } from "@app/data.ts";
import fetchCass from "@helper/fetchCaas";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import fetchTMApi from "@src/app/components/helper/fetchTMApi";
import getOSProcessedArticleData, {
  ProcessedArticleData,
} from "@src/app/components/helper/transformer/useOsResponse";
import { SearchPageQuery } from "@src/app/query/SearchPageQueries";
import { RouteParam } from "@typings/Data";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import type { OSResponse, PartialCueArticle } from "@typings/Opensearch";
import { Match, MatchResult } from "path-to-regexp";

type SearchValueRouteParams = {
  searchValue: string;
};

export type SearchPayloadResponseType = {
  data: ProcessedArticleData[];
  searchedText: string;
  resultCount: number;
};

export type SearchErrorResponseType = {
  message?: string;
  searchedText: string;
};

export const isSearchMatchResult = (
  a: Match<RouteParam>,
): a is MatchResult<SearchValueRouteParams> => {
  return (
    "searchValue" in
    ((a as MatchResult<SearchValueRouteParams>).params as RouteParam)
  );
};

export const fetchSearchPageData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<SearchPayloadResponseType, SearchErrorResponseType>
> => {
  const searchedText = isSearchMatchResult(urlComponent)
    ? urlComponent.params.searchValue
    : "";

  try {
    const data = (await fetchCass({
      searchQuery: SearchPageQuery(searchedText, 5),
    })) as OSResponse<PartialCueArticle>;

    const hits = data.payload.hits.total.value;

    const processedData = getOSProcessedArticleData(data);

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        data: processedData,
        searchedText: searchedText,
        resultCount: hits,
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: {
        message: error.message,
        searchedText: searchedText,
      },
    };
  }
};

export async function fetchMoreSearchedStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  const response: ProcessedArticleData[] = await fetchTMApi(
    "more-search-stories",
    "POST",
    {
      searchedText: requestData.filterArticlesBasedOn,
      sort: requestData.sort,
      size: 5,
    },
  );

  return response;
}
