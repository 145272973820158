import { HoroscopeSection } from "@components/Blocks/Home/HoroscopeSection";
import { JobsListing } from "@components/Blocks/Home/JobsListing";
import { MovieTimingsSection } from "@components/Blocks/Home/MovieTimingsSection";
import PodcastPlayer from "@components/Blocks/LatestPodcasts/PodcastPlayer";
import LatestVideoPlayer from "@components/Blocks/LatestVideo/LatestVideoPlayer";
import { ForexSection } from "@container/SideBlock/ForexSection";
import { GoldSection } from "@container/SideBlock/GoldSection";
import MostRead from "@container/SideBlock/MostRead";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import { useEffect, useState } from "react";

export type RenderArticleBlocksProps = {
  pageNumber: number;
  pageUrl: string;
  hasOutbrain?: boolean;
};

const blockConfig = [
  "latestPortraitVideo",
  "latestLandscapeVideo",
  "latestPodcast",
  "mostReadNews",
  "Outbrain",
  "Horoscope",
  "Movies",
  "Gold",
  "Currency",
  "Jobs",
];

export default function RenderArticleBlocks({
  pageNumber,
  pageUrl,
  hasOutbrain = true,
}: RenderArticleBlocksProps) {
  const [portraitVideoOffset, setPortraitVideoOffset] = useState<number>(0);
  const [landscapeVideoOffset, setLandscapeVideoOffset] = useState<number>(0);
  const [blockIndex, setBlockIndex] = useState<number>(0);

  // Update blockIndex when pageNumber changes.
  useEffect(() => {
    const newBlockIndex = pageNumber % blockConfig.length;
    setBlockIndex(newBlockIndex);

    if (newBlockIndex == 0 && pageNumber > 0) {
      setPortraitVideoOffset(portraitVideoOffset + 1);
    }
    if (newBlockIndex == 1 && pageNumber > 1) {
      setLandscapeVideoOffset(landscapeVideoOffset + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const renderBlock = (blockName: string) => {
    switch (blockName) {
      case "latestPortraitVideo":
        return (
          <LatestVideoPlayer
            offset={portraitVideoOffset}
            type={"PORTRAITS"}
            className={"aspect-portrait"}
          />
        );
      case "latestLandscapeVideo":
        return (
          <LatestVideoPlayer
            offset={landscapeVideoOffset}
            type={"LANDSCAPE"}
            className={"aspect-landscape"}
          />
        );
      case "latestPodcast":
        return <PodcastPlayer />;
      case "mostReadNews":
        return <MostRead />;
      case "Outbrain":
        return hasOutbrain && <Outbrain pathName={pageUrl} version="AR_1" />;
      case "Horoscope":
        return <HoroscopeSection />;
      case "Movies":
        return <MovieTimingsSection />;
      case "Gold":
        return <GoldSection />;
      case "Currency":
        return <ForexSection />;
      case "Jobs":
        return <JobsListing />;
      default:
        return null;
    }
  };

  return (
    <div className="lg:w-4/12 lg:min-h-[800px]">
      {/* Render blocks based on blockIndex */}
      {renderBlock(blockConfig[blockIndex])}
    </div>
  );
}
