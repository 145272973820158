import loadingGif from "@assets/loader.gif";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getErrorMessage } from "@helper/getErrorMessage";
import { ProcessedArticleData } from "@helper/transformer/useOsResponse";
import { getWindowOrigin, isArrayWithElements } from "@helper/utils";
import useIntersectionObserver from "@hooks/useIntersectionObserver";
import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";

import { useComscoreCall } from "../useAnalyticsCall/useTMCustomEventAnalyticsCall";

interface LoadMoreProps {
  rootClassName?: string;
  onLoadMore: () => Promise<ProcessedArticleData[] | number>;
  hasMore?: boolean;
  loadText?: string;
  articleEventLabel?: boolean;
  landingPage?: string;
  loadDefaultGaEvent?: boolean;
  mobileInfiniteScroll?: boolean;
}

/**
 *
 * @param onLoadMore the action will be performed when user click the load more button, from the design point of view
 * the load more component should not "understand" or "care" about the action content
 * @param hasMore this boolean indicator from parent logic to tell the component to hide itself or not
 * @returns
 */
export default function LoadMore({
  rootClassName = "",
  onLoadMore,
  hasMore = true,
  loadText = "More Stories",
  articleEventLabel,
  landingPage,
  loadDefaultGaEvent = true,
  mobileInfiniteScroll = false,
}: LoadMoreProps): React.ReactElement {
  const loadmoreRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const sendDataToGTM = useGTMDispatch();
  const origin = getWindowOrigin();

  const dataToGTMOnInfiniteScroll = useCallback(
    (eventLabelVal: string, landingPageVal: string) => {
      sendDataToGTM({
        event: "infinite_scroll",
        eventCategory: "Infinite Scroll",
        eventAction: "1",
        eventLabel: eventLabelVal,
        landingPage: landingPageVal,
      });
    },
    [sendDataToGTM],
  );

  const dataToComScore = useComscoreCall();

  const handleLoadMore = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await onLoadMore();
      if (
        loadDefaultGaEvent &&
        (isArrayWithElements(response) || response > 0) &&
        typeof window !== "undefined"
      ) {
        let eventLabelVal = window.location.href;
        let landingPageVal = window.location.origin + window.location.pathname;
        if (Array.isArray(response) && response.length > 0) {
          eventLabelVal = articleEventLabel
            ? `${origin}${response[0].urlPath}`
            : window.location.href;
        }
        if (landingPage) landingPageVal = landingPage;
        dataToGTMOnInfiniteScroll(eventLabelVal, landingPageVal);
        dataToComScore();
      }
    } catch (err: unknown) {
      setError(getErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, [
    onLoadMore,
    loadDefaultGaEvent,
    landingPage,
    dataToGTMOnInfiniteScroll,
    articleEventLabel,
    dataToComScore,
    origin,
  ]);

  const handleVisibilityChange = useCallback(
    (element: Element) => {
      if (element === loadmoreRef.current && hasMore) {
        handleLoadMore().catch((err) => {
          console.error(getErrorMessage(err));
        });
      }
    },
    [handleLoadMore, hasMore],
  );

  const { observe, unobserve } = useIntersectionObserver(
    handleVisibilityChange,
    {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    },
  );

  useEffect(() => {
    const element = loadmoreRef.current;
    const onScroll = () => {
      document.removeEventListener("scroll", onScroll);
      if (element) {
        observe(element);
      }
    };

    if (window.innerWidth >= 768) {
      document.addEventListener("scroll", onScroll);
    }

    const isMobileView = window.innerWidth < 768;
    if (isMobileView && mobileInfiniteScroll) {
      document.addEventListener("scroll", onScroll);
    }

    return () => {
      if (window.innerWidth >= 768) {
        document.removeEventListener("scroll", onScroll);
      }
      if (isMobileView && mobileInfiniteScroll) {
        document.removeEventListener("scroll", onScroll);
      }
      if (element) {
        unobserve(element);
      }
    };
  }, [hasMore, mobileInfiniteScroll, observe, unobserve]);

  const renderButtonText = () => {
    if (error) {
      return error;
    }

    return loadText;
  };

  return (
    <>
      {isLoading ? (
        <LoadingGif />
      ) : (
        <div ref={loadmoreRef} className="col-span-full">
          <div
            className={cx(
              "col-span-full cursor-pointer text-center md:hidden",
              {
                hidden: !hasMore,
              },
              rootClassName,
            )}
            onClickCapture={() => {
              handleLoadMore().catch((err) => {
                console.error(getErrorMessage(err));
              });
            }}
          >
            <button className="uppercase bg-pink-500 px-md py-xs rounded text-white-100 text-base font-semibold">
              {renderButtonText()}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export function LoadingGif() {
  return (
    <div data-testid="loading-gif" className="mx-auto size-10">
      <img src={loadingGif} alt="Loading..." className="rounded" />
    </div>
  );
}
