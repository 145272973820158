import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function SearchMetaData(
  searchedText: string,
): PageMetaDataValues {
  const sectionName = "miscellaneous";
  const metaTitle = `Search For "${searchedText}" - Tamil Murasu`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    slug: `search/site/${searchedText}`,
    description: "",
  };

  const gaData: GADataProps = {
    level2: sectionName,
    contentcat: 1,
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
