import { twMerge } from "tailwind-merge";

type CurrencyCardType = {
  title?: string;
  imgUrl: string;
  priceChange: string;
  currentPrice: number | string;
  symbol: string;
};
export const CurrencyCard = ({
  symbol,
  imgUrl,
  title,
  priceChange,
  currentPrice,
}: CurrencyCardType) => {
  const isNegative = Number(priceChange) < 0;
  const hasNoChange = Number(priceChange) === 0;
  return (
    <div className="w-[50%] mt-[10px]">
      {title && <div>{title}</div>}
      <div className="flex">
        <img
          alt={symbol + "-card"}
          style={{ height: 50, width: 50 }}
          height={50}
          width={50}
          src={imgUrl}
        />
        <div className="flex flex-col ml-3 mt-3">
          <div className="text-lg h-fit leading-[13px]">
            <span className="text-xs text-gray-700">{symbol}</span>{" "}
            {currentPrice}
          </div>
          <div
            className={twMerge(
              "text-md font-normal opacity-90",
              isNegative
                ? "text-red-100"
                : hasNoChange
                  ? "text-grey-700"
                  : "text-green-400 leading-[12px] mt-1",
            )}
          >
            {priceChange}%
            <span
              className={twMerge(
                "ml-1",
                isNegative ? "inline-block rotate-180" : "",
              )}
            >
              &#9652;
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
