import { ReactElement } from "react";
import { twMerge } from "tailwind-merge";

type WrapperContainerProps = {
  children: ReactElement;
  className?: string;
};

export default function WrapperContainer({
  children,
  className,
}: WrapperContainerProps) {
  return (
    <div className={twMerge("flex justify-center", className)}>{children}</div>
  );
}
