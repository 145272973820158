import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { YouTubeEmbed, YouTubeEmbedProps } from "react-social-media-embed";

import WrapperContainer from "../ParagraphElements/WrapperContainer";
import { SOCIAL_EMBED_HEIGHT, SOCIAL_EMBED_HEIGHT_MOBILE } from "./constants";

type YouTubeEmbedCustomProps = {
  url: YouTubeEmbedProps["url"];
  className?: string;
};
export default function YouTubeEmbedCustom({
  url,
  className = "",
}: YouTubeEmbedCustomProps): React.ReactElement {
  const { isScreenSM } = useWindowSize();
  const height = isScreenSM ? SOCIAL_EMBED_HEIGHT : SOCIAL_EMBED_HEIGHT_MOBILE;

  return (
    <WrapperContainer className={className}>
      <YouTubeEmbed url={url} width={"100%"} height={height} />
    </WrapperContainer>
  );
}
