import { Helmet } from "react-helmet-async";

import {
  ANDROID_PACKAGE,
  APP_NAME,
  FB_APP_ID,
  FB_PAGES,
  IOS_APP_STORE_ID,
  ITUNES_APP,
  OG_IMAGE,
  OG_SITE_NAME,
  ROBOTS,
  URL_ORIGIN,
} from "./MetaTagsConstants";

export type MetaTagsProps = {
  title: string;
  description: string;
  slug: string;
  shortLink?: string;
  image?: string;
  ogType?: string;
  keywords?: string[];
  publishedTime?: string;
  modifiedTime?: string;
  isArticlePage?: boolean;
  jsonLd?: object;
};

export default function MetaTags({
  title,
  description,
  slug,
  shortLink,
  image,
  ogType,
  keywords = [],
  publishedTime,
  modifiedTime,
  isArticlePage = false,
  jsonLd,
}: MetaTagsProps) {
  const canonicalUrl: string = URL_ORIGIN + (slug === "home" ? "" : slug);
  const shortLinkUrl: string = URL_ORIGIN + shortLink;
  const ogImage: string = image ? image : OG_IMAGE;

  return (
    <Helmet>
      <title>{title}</title>
      {/* Search Engine tags */}
      <meta name="robots" content={ROBOTS} />
      {slug && <link rel="canonical" href={canonicalUrl} />}
      {shortLink && <link rel="shortlink" href={shortLinkUrl} />}
      {description && <meta name="description" content={description} />}
      {keywords.length > 0 && (
        <>
          <meta name="keywords" content={keywords.join(", ")} />
          <meta name="news_keywords" content={keywords.join(", ")} />
        </>
      )}

      {/* Open Graph tags */}
      <meta property="og:site_name" content={OG_SITE_NAME} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={title} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {ogType && <meta property="og:type" content={ogType} />}
      {modifiedTime && (
        <meta property="og:updated_time" content={modifiedTime} />
      )}

      {/* Dcterms tags */}
      <meta name="dcterms.title" content={title} />
      <meta name="dcterms.type" content="text" />
      <meta name="dcterms.format" content="text/html" />
      <meta name="dcterms.identifier" content={canonicalUrl} />
      {isArticlePage && <meta name="dcterms.date" content={publishedTime} />}

      {/* FB tags */}
      <meta property="fb:pages" content={FB_PAGES} />
      <meta property="fb:app_id" content={FB_APP_ID} />

      <link rel="manifest" href="/manifest.json" />

      <meta data-rh="true" property="al:ios:url" content={canonicalUrl} />
      <meta
        data-rh="true"
        property="al:ios:app_store_id"
        content={IOS_APP_STORE_ID}
      />
      <meta data-rh="true" name="apple-itunes-app" content={ITUNES_APP} />
      <meta data-rh="true" property="al:ios:app_name" content={APP_NAME} />
      <meta property="al:android:url" content={canonicalUrl} />
      <meta property="al:android:package" content={ANDROID_PACKAGE} />
      <meta name="google-play-app" content={ANDROID_PACKAGE} />
      <meta property="al:android:app_name" content={APP_NAME} />
      <meta property="al:web:url" content={canonicalUrl} />

      {/* Article tags */}
      {isArticlePage && (
        <meta property="article:published_time" content={publishedTime} />
      )}
      {isArticlePage && (
        <meta property="article:modified_time" content={modifiedTime} />
      )}

      {jsonLd ? (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      ) : null}
    </Helmet>
  );
}
