import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import ArticleImage from "@components/Carousel/ArticleImage";
import RelatedArticleCarousel from "@components/Carousel/RelatedArticleCarousel";
import { AdsImu1, AdsMidArticle } from "@elements/Advertisement/variant/AdsImu";
import {
  CompanionAdsImu1,
  CompanionAdsMidArticle,
} from "@elements/Advertisement/variant/CompanionAdImu";
import Paragraph from "@elements/Typography/Paragraph";
import { RenderTags } from "@elements/Typography/Tags";
import { getUserType } from "@helper/mySPH";
import {
  getWindowOrigin,
  isArrayWithElements,
  isClientSide,
} from "@helper/utils";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import {
  ArticleAdPageTargetingType,
  ArticleAdSlotTargetingType,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import {
  type ProcessedArticleBody,
  ProcessedAuthorContent,
  ProcessedTagsContent,
} from "@typings/Opensearch";
import React, { useCallback, useEffect, useState } from "react";

import ApplyParagraphAnnotations from "./ApplyParagraphAnnotations";
import { YOUTUBE_WATCH_URL } from "./Embeds/constants";
import Embed from "./Embeds/Embed";
import YouTubeEmbedCustom from "./Embeds/YouTubeEmbedCustom";
import GetLimitedParagraphsFromArticleBody from "./GetLimitedParagraphsFromArticleBody";
import ParagraphList from "./ParagraphElements/ParagraphList";
import PullQuoteCard from "./ParagraphElements/PullQuoteCard";
import SubHead from "./ParagraphElements/SubHead";
import WrapperContainer from "./ParagraphElements/WrapperContainer";
const socialIconList = ["whatsapp", "facebook", "x", "copylink"];
import CASBlock from "@components/Blocks/CASBlock/CASBlock";
import ShareSocialButton from "@elements/ShareSocial/ShareSocialButton";
import debounce from "@helper/debounce";
import { CAS_CAMPAIGN_ID } from "@helper/getEnvVariables";
import setArticleVisitCookie, {
  SphUserArticleCountCookie,
} from "@helper/setArticleVisitCookie";
import { formatDate } from "@helper/useDateFormat";
import { isElementInViewPort } from "@helper/visibilityHelpers";
import { useTMCustomEventAnalyticsCall } from "@hooks/useAnalyticsCall/useTMCustomEventAnalyticsCall";
import useCasScript from "@hooks/useCasScript";
import Cookies from "js-cookie";

import Paywall from "./ArticlePaywall";
import Regwall, { ArticleGaEventsDataProps } from "./ArticleRegwall";

export type ArticleParagraphProps = {
  articleId: string;
  articleTitle: string;
  articleBody: ProcessedArticleBody;
  relatedStories: ProcessedArticleData[];
  sectionName: string | undefined;
  tags?: ProcessedTagsContent[];
  pageUrl: string;
  pageNumber: number;
  dateCreated: string;
  isInfiniteScrollArticle?: boolean;
  authors?: ProcessedAuthorContent[];
  articlePageTarget?: ArticleAdPageTargetingType[];
  articleSlotTarget?: ArticleAdSlotTargetingType[];
};

export default function ArticleParagraph({
  articleId,
  articleTitle,
  articleBody,
  relatedStories,
  sectionName,
  tags,
  pageUrl,
  pageNumber,
  dateCreated,
  isInfiniteScrollArticle,
  authors,
  articlePageTarget,
  articleSlotTarget,
}: ArticleParagraphProps) {
  const origin = getWindowOrigin();
  const [articleParagraphs, setArticleParagraphs] =
    useState<ProcessedArticleBody | null>(articleBody);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tmCustomGaEventsProps = {
    articleid: articleId,
    contenttype: "1",
    chapter1: "",
    level2: sectionName,
    pubdate: formatDate(dateCreated, "yyyy-MM-dd HH:mm:ss"),
    title: articleTitle,
    url: origin + pageUrl,
    keyword: tags ? tags.map((tags) => tags.name).join(", ") : "",
    byline: authors ? authors.map((author) => author.name).join(", ") : "",
  } as ArticleGaEventsDataProps;

  const TmCustomGaEvent = useTMCustomEventAnalyticsCall();
  const [showRegwall, setShowRegwall] = useState<boolean>(false);
  const [showPaywall, setShowPaywall] = useState<boolean>(false);
  const [cookieData, setCookieData] = useState<SphUserArticleCountCookie>();
  const { isScriptReady: isCasScriptReady } = useCasScript();

  const handleScroll = useCallback(() => {
    if (!isClientSide) return;
    const articleBody = document.querySelectorAll(
      `.article-body-wrapper-${articleId}`,
    );
    articleBody.forEach((article) => {
      if (isElementInViewPort(article, 0)) {
        // Set user page visit count and ids.
        setArticleVisitCookie({ articleId });

        // Retrieve the existing cookie
        const cookieName = "sph_user_article_count";
        const existingCookie = Cookies.get(cookieName);
        if (existingCookie) {
          const cookieDataParsed = JSON.parse(
            existingCookie,
          ) as SphUserArticleCountCookie;
          setCookieData(cookieDataParsed);
        }
      }
    });
  }, [articleId]);

  useEffect(() => {
    if (!isClientSide) return;
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const userType = getUserType();

    let visitCount = 0;
    let UserArticleVisitNumber = visitCount;
    if (cookieData) {
      visitCount = cookieData.count;
      UserArticleVisitNumber = visitCount;
      // Get the visit count
      if (cookieData.nids.includes(articleId)) {
        UserArticleVisitNumber = cookieData.nids.indexOf(articleId) + 1;
      }
    }

    if (
      visitCount >= 5 &&
      UserArticleVisitNumber >= 5 &&
      (userType === "y-anoy" || userType === undefined)
    ) {
      setShowRegwall(true);
    }
    if (
      visitCount >= 7 &&
      UserArticleVisitNumber >= 7 &&
      userType !== "y-sub" &&
      userType === "y-reg"
    ) {
      setShowRegwall(false);
      setShowPaywall(true);
    }
  }, [articleId, cookieData]);

  useEffect(() => {
    if (showRegwall || showPaywall) {
      setArticleParagraphs(GetLimitedParagraphsFromArticleBody(articleBody, 1));
    } else {
      setArticleParagraphs(articleBody);
    }

    const cookieName = "sph_user_article_count";
    const existingCookie = Cookies.get(cookieName);
    if (showRegwall) {
      // Set GA events.
      TmCustomGaEvent({
        event: "customEvent",
        eventCategory: "Article Hard Regwall",
        eventAction: "Visible",
        abVariant: "hard regwall",
        eventLabel: existingCookie,
        ...tmCustomGaEventsProps,
      });
    }
    if (showPaywall) {
      // Set GA events.
      TmCustomGaEvent({
        event: "customEvent",
        eventCategory: "Article Paywall",
        eventAction: "Visible",
        abVariant: "article paywall",
        eventLabel: existingCookie,
        ...tmCustomGaEventsProps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleBody, showRegwall, showPaywall]);

  let paragraphCount = 0;
  const pageAdTarget = PageAdTargetingTypeEnum.ARTICLE;

  let adsImu1 = (
    <AdsImu1
      pageAdTargetType={pageAdTarget}
      sectionName={sectionName}
      rootClassName={"my-2 border-y border-gray-2200"}
      articlePageTarget={articlePageTarget}
    />
  );
  let adsMidArticle = (
    <AdsMidArticle
      pageAdTargetType={pageAdTarget}
      sectionName={sectionName}
      rootClassName={"my-2 border-y border-gray-2200"}
      articlePageTarget={articlePageTarget}
    />
  );

  if (isInfiniteScrollArticle) {
    adsImu1 = (
      <CompanionAdsImu1
        uniqueSectionName={sectionName}
        pageAdTargetType={pageAdTarget}
        pageNumber={pageNumber}
        articlePageTarget={articlePageTarget}
        articleSlotTarget={articleSlotTarget}
      />
    );
    adsMidArticle = (
      <CompanionAdsMidArticle
        uniqueSectionName={sectionName}
        pageAdTargetType={pageAdTarget}
        pageNumber={pageNumber}
        articlePageTarget={articlePageTarget}
        articleSlotTarget={articleSlotTarget}
      />
    );
  }

  return (
    <div
      data-testid="article-body"
      className={`article-body-wrapper-${articleId}`}
    >
      {articleParagraphs &&
        articleParagraphs.map((element, index) => {
          let bannersAndAds = null;
          if (paragraphCount === 0) {
            if (showRegwall) {
              bannersAndAds = (
                <>
                  <Regwall articleData={tmCustomGaEventsProps} />
                  {adsImu1}
                </>
              );
            } else if (showPaywall) {
              bannersAndAds = (
                <>
                  <Paywall articleData={tmCustomGaEventsProps} />
                  {adsImu1}
                </>
              );
            } else {
              bannersAndAds = <>{adsImu1}</>;
            }
          } else if (paragraphCount === 5) {
            bannersAndAds = <>{adsMidArticle}</>;
          }

          switch (element.type) {
            case "paragraph":
              paragraphCount++;
              return (
                <React.Fragment key={index}>
                  <Paragraph testid={`paragraph-${index}`}>
                    {ApplyParagraphAnnotations(
                      element.value,
                      element.annotations,
                    )}
                  </Paragraph>
                  {bannersAndAds}
                </React.Fragment>
              );

            case "sub_head":
              return (
                <React.Fragment key={index}>
                  <SubHead element={element} />
                </React.Fragment>
              );

            case "image": {
              const imageHeight =
                element.image?.height && element.image.height >= 1460
                  ? 1460
                  : 550;
              return (
                <ArticleImage
                  key={index}
                  url={element.image?.url}
                  alt={element.caption}
                  caption={element.caption}
                  credit={element.credit}
                  height={imageHeight}
                  className="mb-10 mt-2"
                />
              );
            }

            case "embed":
              return (
                <React.Fragment key={index}>
                  <Embed className="my-lg" src={element.embedUrl} />
                </React.Fragment>
              );

            case "pull_quote":
              return (
                <React.Fragment key={index}>
                  <PullQuoteCard
                    quote={element.quote}
                    quotee={element.quotee}
                    quoteLabel={element.quoteLabel}
                    quoteCredit={element.quoteCredit}
                  />
                </React.Fragment>
              );

            case "list_bulleted":
            case "list_numbered": {
              const listType = element.type === "list_bulleted" ? "ol" : "ul";
              const listItems = element.listItem;
              const listStyle =
                element.listStyle === "decimal" ? "list-decimal" : "list-disc";
              return (
                <React.Fragment key={index}>
                  <ParagraphList
                    listStyle={listStyle}
                    listType={listType}
                    listItems={listItems}
                  />
                </React.Fragment>
              );
            }

            case "youtube_video": {
              const url = `${YOUTUBE_WATCH_URL}${element.youtubeId}`;
              return (
                <React.Fragment key={index}>
                  <YouTubeEmbedCustom url={url} className="my-lg" />
                </React.Fragment>
              );
            }

            case "brightcove_video": {
              return (
                <React.Fragment key={index}>
                  <WrapperContainer className="block my-lg">
                    <BrightcoveVideo videoId={element.videoId} />
                  </WrapperContainer>
                </React.Fragment>
              );
            }

            default:
              return null;
          }
        })}

      {isCasScriptReady && (
        <CASBlock casId={CAS_CAMPAIGN_ID} index={pageNumber} />
      )}

      <ShareSocialButton
        socialIconList={socialIconList}
        pagePath={pageUrl}
        mbClassName="bg-white-100 z-10 rounded-3xl mb-5 mr-sm"
        deskClassName="mb-5 mt-10"
      />

      {/* Tags dont dont render If alias not available in payload*/}
      {isArrayWithElements(tags) && (
        <RenderTags tagsWrapper="mb-5 lg:mb-6" tags={tags} />
      )}

      {isArrayWithElements(relatedStories) && (
        <RelatedArticleCarousel
          relatedArticles={relatedStories}
          rootClassName="mt-6 overflow-hidden"
          testId="related-article-carousel"
        />
      )}
    </div>
  );
}
