import downArrow from "@assets/actions/down-arrow.svg";
import ArticleCarousel from "@components/Carousel/ArticleCarousel";
import { isArrayWithElements } from "@components/helper/utils";
import ArticleParagraph from "@elements/ArticleParagraph/ArticleParagraph";
import GetReadingTime from "@elements/ArticleParagraph/GetReadingTime";
import Author from "@elements/Author/Author";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { StandFirst } from "@elements/Typography/Paragraph";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import {
  ArticleAdPageTargetingType,
  ArticleAdSlotTargetingType,
  PageAdTargetValue,
} from "@typings/Ads.d";
const socialIconList = ["whatsapp", "facebook", "x", "copylink"];
import LatestVideo from "@components/Blocks/LatestVideo/LatestVideo";
import MainBlock from "@container/MainBlock";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import ShareSocialButton from "@elements/ShareSocial/ShareSocialButton";
import { getDateTime } from "@helper/useDateFormat";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import AiSummary from "@src/app/components/Elements/AiSummary/AiSummary";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

export type ArticleContentCardProps = {
  articleData: ProcessedArticleData;
  relatedStories: ProcessedArticleData[];
  pageAdTargetValue: PageAdTargetValue;
  isInfiniteScrollArticle?: boolean;
  pageNumber: number;
  articlePageTarget?: ArticleAdPageTargetingType[];
  articleSlotTarget?: ArticleAdSlotTargetingType[];
  hasOutbrain?: boolean;
};

export default function ArticleContentCard({
  articleData,
  relatedStories,
  pageNumber,
  isInfiniteScrollArticle = false,
  articlePageTarget,
  articleSlotTarget,
  hasOutbrain = true,
}: ArticleContentCardProps) {
  const {
    id,
    title,
    sectionName,
    tags,
    authors,
    dateCreated,
    dateEdited,
    kicker,
    articleBody,
    standFirst,
    urlPath,
    imageGallery,
    englishHeadline,
    englishSummary,
  } = articleData;

  const pageUrl = `${urlPath}`;

  // Get page reading time.
  const readingTime = GetReadingTime(articleBody, urlPath);
  const { isScreenLG } = useWindowSize();
  const [isDateOpen, setDateIsOpen] = useState<boolean>(false);

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setDateIsOpen((prev) => !prev);
  };

  return (
    <MainBlock
      showBreadcrumbs={!isInfiniteScrollArticle}
      sectionName={sectionName}
      pageName={title}
      showTitle={false}
    >
      <article className="lg:mt-1">
        <div className="font-primary font-bold text-2sm leading-430 text-black-400">
          {kicker}
        </div>

        <Heading className="text-3md lg:text-lg font-bold font-primary text-gray-100">
          {title}
        </Heading>

        <div className="w-full inline-block py-5">
          <div className="inline-block">
            <button onClick={toggleDropdown} className="dropdown-button flex">
              <Date className="mr-2 text-gray-100">
                {getDateTime(dateCreated)}
              </Date>
              {dateEdited && (
                <img
                  src={downArrow}
                  alt={"dropdown-arrow"}
                  width={10}
                  height={10}
                  className={twMerge(
                    "mt-2 duration-500",
                    isDateOpen && "-rotate-180",
                  )}
                />
              )}
            </button>
            {isDateOpen && dateEdited && (
              <>
                <div className="mb-2 font-primary font-normal text-gray-150 text-xs lg:flex">
                  <span className="mr-1">மாற்றம் செய்யப்பட்ட நாள்:</span>
                  <Date className="mt-2 lg:mt-0">
                    {getDateTime(dateEdited)}
                  </Date>
                </div>
              </>
            )}

            <div className="font-primary font-medium text-gray-150 text-xs">
              {readingTime} mins read
            </div>
          </div>

          <div className="float-right">
            <ShareSocialButton
              socialIconList={socialIconList}
              pagePath={pageUrl}
              mbClassName="absolute right-0 bg-white-100 z-10 rounded-3xl mr-sm"
              deskClassName="mb-5"
            />
          </div>
        </div>

        {standFirst && (
          <StandFirst className="border-t border-solid border-gray-2100 mt-4xs mb-5 pt-xxs">
            {standFirst}
          </StandFirst>
        )}

        {isArrayWithElements(imageGallery) && (
          <ArticleCarousel
            renderItems={imageGallery}
            sectionName={sectionName}
          />
        )}

        {isArrayWithElements(authors) && authors[0].urlPath && (
          <Author
            destination={authors[0].urlPath ? authors[0].urlPath : ""}
            authorSrc={authors[0].authorImage ? authors[0].authorImage.url : ""}
            authorName={authors[0].name}
          />
        )}

        {englishHeadline && englishSummary && (
          <AiSummary
            englishHeadline={englishHeadline}
            englishSummary={englishSummary}
          />
        )}

        {isArrayWithElements(articleBody) && (
          <ArticleParagraph
            articleId={id}
            articleTitle={title}
            articleBody={articleBody}
            relatedStories={relatedStories}
            sectionName={sectionName}
            pageUrl={pageUrl}
            dateCreated={dateCreated}
            tags={tags}
            authors={authors}
            pageNumber={pageNumber}
            isInfiniteScrollArticle={isInfiniteScrollArticle}
            articlePageTarget={articlePageTarget}
            articleSlotTarget={articleSlotTarget}
          />
        )}
      </article>

      {/* Video block */}
      {isScreenLG && !isInfiniteScrollArticle && <LatestVideo />}

      {/* Outbrain block */}
      {!isInfiniteScrollArticle && hasOutbrain && isScreenLG && (
        <div className="border-t border-pink-300 pt-6">
          <Outbrain pathName={pageUrl} version="AR_1" />
        </div>
      )}
    </MainBlock>
  );
}
